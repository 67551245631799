<template>
<svg data-svg-lvl-3="cover-top-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><clipPath id="tc-cp"><polygon class="tc-c-1" points="31.19 42.3 31.19 192.73 31.19 192.73 31.19 343.15 482.87 343.15 482.87 192.73 482.87 192.73 482.87 42.3 31.19 42.3"/></clipPath></defs><g id="Крышки_Контур"><g class="tc-c-4"><image width="1182" height="788" transform="translate(12.82 30.06) scale(0.41)"  v-show="params.isCoverPlanner" :href="`${$CONST.cnstrAssetsUrl}/others/PH26V.webp`"/></g><polygon class="tc-c-5" points="31.19 42.3 31.19 192.73 31.19 192.73 31.19 343.15 482.87 343.15 482.87 192.73 482.87 192.73 482.87 42.3 31.19 42.3"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.tc-c-1,.tc-c-5{fill:none;}.tc-c-2{opacity:0.3;}.tc-c-3{fill:#64ff00;}.tc-c-4{clip-path:url(#tc-cp);}.tc-c-5{stroke:#232728;stroke-miterlimit:2;stroke-width:1.14px;}</style>

