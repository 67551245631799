<template>
<svg data-svg-lvl-3="cover-back-contour" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514 430"><defs><linearGradient id="bc-lg" x1="95.5359" y1="54.4527" x2="111.8978" y2="54.4527" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#919191"/><stop offset=".2086" stop-color="#c2c2c2"/><stop offset=".4492" stop-color="#919191"/><stop offset=".7968" stop-color="#525252"/><stop offset="1" stop-color="#919191"/></linearGradient><linearGradient id="bc-lg-2" x1="96.7726" y1="183.4456" x2="110.6612" y2="183.4456" href="#bc-lg"/></defs><g id="Задник_Контур"><path class="bc-c-2" d="M357.4624,15.9873l-235.8994.0061c-5.0425,0-9.1304,4.0879-9.1299,9.1304v2.0947l.0005,15.8276c0,5.0425-4.0879,9.1301-9.1304,9.1301h-.0908v353.4548c0,5.0422,4.0879,9.1299,9.1299,9.1299h277.8003v.0068h2.0952c5.0425,0,9.1304-4.0876,9.1304-9.1301V59.8933l-43.9058-43.906Z"/><path class="bc-c-1" d="M98.0092,6.6987h12.1288s1.7599,19.2158,1.7599,95.508h-16.3619c0-83.8073,2.4733-95.508,2.4733-95.508Z"/><path class="bc-c-3" d="M96.7726,102.2067v121.9535c0,19.9768,4.1585,40.5243,4.1585,40.5243h5.5716s4.1585-19.6914,4.1585-40.5243v-121.9535h-13.8886Z"/><path class="bc-c-4" d="M97.571,10.3947l-1.152,21.6152s-5.4493,5.2247-5.4493,17.5913,1.6172,27.3967,2.6636,31.2018c1.0464,3.8051-1.522,6.3735-1.522,6.3735,0,0-3.9954-25.2088-3.9954-37.1949s9.455-39.587,9.455-39.587Z"/></g></svg></template>


<script>
/** Этот компонент генерируется автоматически, руками ничего менять нельзя!!! */
  
export default {props: { params: { type: Object, required: true, }, }, data() { return {} },}
</script>

<style scoped>.bc-c-1{fill:url(#bc-lg);}.bc-c-1,.bc-c-2,.bc-c-3,.bc-c-4{stroke:#232728;stroke-miterlimit:2;stroke-width:1.1415px;}.bc-c-2{fill:gray;}.bc-c-5{fill:#ff931e;stroke-width:0px;}.bc-c-6{opacity:.3;}.bc-c-3{fill:url(#bc-lg-2);}.bc-c-4{fill:#727272;}</style>

