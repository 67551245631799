import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

let messages = {
  ru: {
    ui: {
      outOfStock: 'Нет в наличии',
      inStock: 'В наличии',
      inStockFor: 'В наличии на',
      onTheWay: 'В пути',
      availability: 'Наличие',
      pcs: 'шт',
      cm: 'см',
      cm2: 'см²',
      m: 'м',
      for: 'на',
      productsOfFormat: 'изделий формата',
      runM: 'пог. м',
      // склонения: 0 | 1 | 2 | 5
      pages: 'нет страниц | {n} страница | {n} страницы | {n} страниц',
      weeks: '{n}-я неделя',

      cornersCover: 'Угол обложки',
      rounded: 'Скругленный',
      square: 'Прямой',
      roundedCorner: 'Скругленный угол',
      squareCorner: 'Прямой угол',

      save: 'Сохранить',
      add: 'Добавить',
      continue: 'Продолжить',
      next: 'Далее',
      stitchingParameters: 'Параметры сшивки',

      persList: 'Список персонализаций',

      // new

      filters: 'Фильтры',
      blockType: 'Вид блока',
      format: 'Формат блока',

      materialType: 'Тип материала',
      color: 'Цвет',
      name: 'Название',
      sale: 'Sale',

      upload: 'Загрузить изображение на обложку',

      // chooseValue: 'Выберите значение',
      // changeLayout: 'смените раскладку',
      // notAvailable: 'Нет в наличии',
      // above: 'от {tiraz} шт',
      // pieces: 'шт.',
      // sku: 'Арт.',
      // block: 'Блок',
      // relatedItems: 'Готовые изделия',
      // orderConstructor: 'Заказывайте в конструкторе ежедневников',
      // share: 'Поделиться',
      // withCirculation: ' тиражом от ',
      // circulation: 'Тираж',
      // nearPanton: 'Ближайший Pantone',
      // material: 'Материал',
      // colors: 'Цвета',
      // recommendPersons: 'Рекомендуются персонализации',

      // sorts: 'По возрастанию',
      // products: 'Изделие',
      // models: 'Модель',
      // springs: 'Скрепление блока',
      // blocks: 'Вид блока',
      // formats: 'Формат блока',
      // materials: 'Название',
      // types: 'Тип материала',
      // new: 'New',

      inputErrorMin: 'минимальное значение {n}',
      inputErrorMax: 'максимальное значение {n}',

      inputErrorMinWidth: 'минимальная длина {n} мм',
      inputErrorMaxWidth: 'максимальная длина {n} мм',

      inputErrorMinHeight: 'минимальная высота {n} мм',
      inputErrorMaxHeight: 'максимальная высота {n} мм',
    },

    title: {
      product: 'Выбор изделия',
      model: 'Выбор модели',
      block: 'Выбор обложки и блока',
      materials: 'Выбор материала',
      personalizations: 'Выбор персонализаций',
      orders: 'Оформление',
      dev: 'Тестовая страница',
    },

    navigator: {
      title: {
        product: 'Шаг 1: Изделие',
        model: 'Шаг 2: Обложка и блок',
        block: 'Шаг 2: Обложка и блок',
        materials: 'Шаг 3: Материал',
        personalizations: 'Шаг 4: Персонализация',
        orders: 'Шаг 5: Оформление',
        add: ' ',
      },
      label: {
        product: 'Изделия',
        model: ' ',
        block: 'Обложка и блок',
        materials: 'Материал',
        personalizations: 'Персонализация',
        orders: 'Оформление запроса',
        add: 'Добавить ещё изделие',
      },
    },
    priceBar: {
      forOne: 'За 1 шт.',
      forEdition: 'За тираж',
      from: 'от',
      rub: 'руб.',
      minEdition: 'Минимальный тираж - 20 шт.',
      noDiscount: 'Тиражная скидка учитывается от 300 шт.',
      discount: 'С учетом тиражной скидки {discount}% при тираже от {quantity} до {quantityNext} шт.',
      extraCharge: 'С учетом тиражной наценки {discount}% при тираже от {quantity} до {quantityNext} шт.',
      discountUnlim: 'С учетом тиражной скидки {discount}% при тираже от {quantity} шт.',
      extraChargeUnlim: 'С учетом тиражной наценки {discount}% при тираже от {quantity} шт.',
      noDiscountOne: 'Цена без учета скидок.',
      noDiscountEdition: 'Стоимость тиража без учета скидок.',
      withDiscount: 'Со скидкой ',
      discountInfo: 'Скидка складывается из{n}.',
      discountInfo2: 'С учетом{n}скидки.',
      discountInfoDealer: '<span style="color:#2FFFFB;">Дилерской</span>',
      discountInfoEdition: '<span style="color:#FF6868;">Тиражной</span>',
      discountInfoSeason: '<span style="color:#6FFF97;">Сезонной</span>',
      discountInfoAnd: 'и',
      discountInfoDealer2: '<span style="color:#2FFFFB;">Дилерская</span> - {dealer}%',
      discountInfoEdition2: '<span style="color:#FF6868;">Тиражная</span> - {edition}%',
      discountInfoSeason2: '<span style="color:#6FFF97;">Сезонная</span> - {season}%',
    },
    cart: {
      addProduct: 'Добавить изделие',
      saveProduct: 'Сохранить изделие',
      totalCost: 'Итоговая стоимость',
      personalizations: 'Персонализации',
      edit: 'Редактировать изделие',
      downloadTemplate: 'Скачать шаблон',
      downloadImages: 'Скачать изображения',
      zoom: 'Увеличить',
    },
    preview: {
      cover: 'Обложка',
      spread: 'Разворот',
      flyleaffront: 'Форзац/Нахзац',
      flyleafend: 'Форзац/Нахзац',
      edge: 'Обрез',
      front: 'Форзац',
      end: 'Нахзац',
      notLayout: 'Изображение не является макетом',
    },

    product: {
      header: 'Конструктор ежедневников',
      subHeader: 'Создайте свое уникальное изделие',
      header2: 'Выберите изделие',
      video: 'Видеоинструкция',
    },
    model: {
      choose: 'Выберите модель',
      p1: 'ежедневника',
      p2: 'еженедельника',
      p3: 'планинга',
      p4: 'алфавитной книжки',
      p7: 'блокнота',
    },
    block: {
      cover: 'Обложка',
      block: 'Блок',
      art: 'Арт',
      main: 'Основной блок',
      additional: 'Дополнительный блок',
      exist: 'Есть',
      notExist: 'Нет',
      pages: 'стр.',
      options: {
        typeBlock: 'Тип блока',
        product: 'Вид изделия',
        model: 'Модель',
        paper: 'Бумага',
        forzac: 'Форзац/Нахзац',
        blockSize: 'Формат изделия',
        numbersPage: 'Количество страниц',
        abcBook: 'Алфавитная книжка',
        blockCorner: 'Уголок блока',
        delivery: 'Ближайшая поставка',
      },
    },
    materials: {
      material: 'Материал',
      materials: 'Простые обложки',
      stitchings: 'Биматериалы',
      laminates: 'Ламинат',
      stitching: 'Сшивка',

      combi1: 'Комби 1',
      combi2: 'Комби 2',
      combi3: 'Комби 3',
      combi4: 'Комби 3+',

      m1: 'Материал 1',
      m2: 'Материал 2',
      m3: 'Материал 3',
      m4: 'Материал 4',
      outer: 'Внешний материал',
      back: 'Материал оборота',
      internal: 'Внутренний материал',
      spine: 'Цвет корешка',
      pocket: 'Материал карманов',
      backPocket: 'Материал карманов оборота',

      fullColorHint: 'Подходит для полноцветной печати',

      options: {
        pantone: 'Ближайший Pantone',
        type: 'Тип материала',
      },
    },
    personalizations: {
      personalizations: 'Персонализации',

      tabs: {
        cover: 'Обложка',
        block: 'Блок',
        options: 'Опции изделия',
        packaging: 'Упаковка',
      },
      preview: 'Внешний вид',
      control: 'Параметры',
      clear: 'Очистить список',

      embossing: {
        embossing: 'Тиснение',
        pancake: 'Блинтовое тиснение',
        foil: 'Тиснение фольгой',
        coloredFoil: 'Тиснение цветной фольгой',
        clicheType: 'Клише',
        individual: 'Индивидуальное тиснение',
        standart: 'Стандартное тиснение',
        area: 'Площадь',
        cliche: 'Выбор клише',
        to50: 'до 50 см²',
        to100: 'до 100 см²',
        from100: 'от 100 см²',
        width: 'Длина',
        height: 'Высота',
        spine: 'Тиснение по корешку',
        clicheMaking: 'Изготовление клише',
        foilColor: 'Цвет фольги',
        upload: 'Загрузить изображение',
      },
      printing: {
        printing: 'Печать по материалу',
        solvent: 'Сольвентная печать',
        colorUV: 'УФ печать',
        reliefUV: 'Рельефная УФ-печать',
        foil: 'Печать фольгой',
        area: 'Площадь',
        to50: 'до 50 см²',
        to100: 'до 100 см²',
        to200: 'до 200 см²',
        to340: 'до 340 см²',
        to680: 'до 680 см²',
        to1200: 'до 1200 см²',
        areaFull: 'Печать на всю площадь',
        upload: 'Загрузить изображение',
      },

      foil: { foil: 'Печать фольгой' },
      flocking: { flocking: 'Выборочное флокирование' },

      uvVarnish: {
        uvVarnish: 'Выборочный УФ-лак',
      },

      sliceColoring: {
        sliceColoring: 'Окраска среза',
        color: 'Цвет',
      },

      flap: {
        flap: 'Хлястик, клапан, карман',
        flap1: 'Хлястик 1',
        flap2: 'Хлястик 2',
        flap4: 'Хлястик 4',
        flap5: 'Хлястик 5',
        flap6: 'Хлястик 6',
        flap7: 'Обрезной уголок',
        flap8: 'Хлястик №8',
        flap9: 'Хлястик №9',
        flap10: 'Хлястик №10',
        flap11: 'Хлястик №11',
        magnet: 'Клапан на магнитах',
        organizer: 'Нашивной карман "Органайзер"',
        trinity: 'Карман "Тройка"',
        trinityH: 'Карман "Тройка"',

        material: 'Материал',
        materialBottom: 'Материал для нижнего кармана',
        materialTop: 'Материал для верхнего кармана',
        materialPocket: 'Материал для петли и кармана',
        rubber20Color: 'Резинка 20 мм',
        threadColor: 'Цвет нитки',
        buttonColor: 'Цвет кнопки',
        sliceColor: 'Цвет среза',
        position: 'Место установки',

        description: {
          flap1: 'на кнопке с петлёй для ручки',
          flap2: 'с петлёй для ручки',
          flap4: 'на магнитной кнопке с петлёй для ручки',
          // flap5: '',
          flap6: 'на скрытой магнитной кнопке',
          flap7: 'на механической кнопке с обрезным уголком ежедневника',
          flap8: 'на скрытом магните с окрасом среза',
          flap9: 'на механической кнопке с прорезями под ручку',
          flap10: 'на магнитной кнопке с окрасом среза',
          flap11: 'на механической кнопке',
          magnet: 'с карманом и петлёй для ручки',
          // organizer: '',
          trinity: 'прошивка по периметру',
          trinityH: 'прошивка по периметру',
        },
      },

      rubber: {
        rubber: 'Резинка',
        '2mm': 'Резинка 2 мм',
        '2mmHorizontal': 'Резинка 2 мм, горизонтальная',
        '6mm': 'Резинка 6 мм',
        '10mm': 'Резинка 10 мм',
        '15mm': 'Резинка 15 мм',
        color: 'Цвет резинки',
        grip: 'Захват',
        material: 'Материал',
        materialNameplate: 'Материал шильды',
        materialGrip: 'Материал захвата',
        nameplate: 'Шильда',
        circle20x20: 'Металлическая, круг 20x20 мм',
        rect15x30: 'Металлическая, прямоугольник 15x30 мм',
        rect115x16: '16x115 мм',
        without: 'Без шильды',
        drawing: 'Нанесение',
        uvPrinting: 'УФ-печать',
        engraving: 'Гравировка',
        nameplateColor: 'Цвет шильды',
        gold: 'Золото',
        silver: 'Серебро',
        nameplateMaking: 'Изготовление шильды',
      },
      loop: {
        loop: 'Петля под ручку',
        '15mm': 'Петля-резинка для ручки, 15 мм',
        '20mm': 'Петля-резинка для ручки, 20 мм',
        pen: 'Петля для ручки',
        penDouble: 'Двойная петля для ручки',
        penLabel: 'Петля-лейбл для ручки на обложку',
        color: 'Цвет резинки',
        material: 'Материал',
        material1: 'Материал первой петли',
        material2: 'Материал второй петли',
        threadColor: 'Цвет нитки',
      },
      seamPerimeter: {
        seamPerimeter: 'Прошивка по периметру',
        color: 'Цвет нитки',
      },
      plastic: {
        plastic: 'Пластиковая сторонка',
        vertical: 'Вертикальная с одной стороны',
        sphere: 'Сфера',
        verticalBoth: 'Вертикальная с двух сторон',
      },
      perforation: {
        perforation: 'Перфорация корешка',
        color: 'Цвет',
      },
      cutting: {
        cutting: 'Вырубка',
        individual: 'Индивидуальная вырубка',
      },
      cuttingPrint: {
        cuttingPrint: 'Индивидуальная вырубка с УФ-печатью на пластике',
      },
      polymer: {
        polymer: 'Полимерная наклейка',
        area: 'Площадь',
        '4cm': 'до 4 см²',
        '9cm': 'до 9 см²',
        '25cm': 'до 25 см²',
      },
      metallic: {
        metallic: 'Металлостикер',
        area: 'Площадь',
        '4cm': 'до 4 см²',
        '9cm': 'до 9 см²',
        '25cm': 'до 25 см²',
        color: 'Цвет',
      },

      stereo: { stereo: 'Наклейки стерео-варио' },
      laser: { laser: 'Фольгированная наклейка с лазерной гравировкой' },
      metallicAccessories: {
        metallicAccessories: 'Металлические аксессуары',
        corners: 'Уголки',
        frames: 'Рамки',
        label: 'Лейбл',
        '2pcs': '2 шт.',
        '4pcs': '4 шт.',
        color: 'Цвет',
        front: 'Лицо',
        back: 'Оборот',
        both: 'Лицо и оборот',
        engravingMaking: 'Гравировка с одной стороны',
      },
      nfc: {
        nfc: 'NFC-метка',
        cliche: 'Выбор клише',
        recording: 'Запись NFC метки',
      },
      magnet: {
        magnet: 'Скрытые магниты под ручку',
        cliche: 'Выбор клише',
      },
      lasse: {
        lasse: 'Ляссе',
        nameplate: 'Логопринт / шильда',
        without: 'Без логопринта и шильды',
        twosided: 'Двусторонний логопринт',
        metal: 'Металлическая шильда',
        plexiglass: 'Шильда из оргстекла',
        single: 'Индивидуальное ляссе',
        double: 'Двойное индивидуальное ляссе',
        drawing: 'Нанесение',
        uvPrinting: 'УФ-печать',
        engraving: 'Гравировка',
        sidesQty: 'Количество сторон',
        withoutSides: 'Без гравировки',
        oneSide: 'С одной стороны',
        bothSides: 'С двух сторон',
        form: 'Форма',
        square: 'Квадрат',
        circle: 'Круг',
        rect: 'Прямоугольник',
        nameplateColor: 'Цвет шильды',
        gold: 'Золото',
        silver: 'Серебро',

        printing: 'Печать на ляссе',
        printing2: 'Печать на 2-м ляссе',
        withoutPrinting: ' Без печати',
        oneColor: '1 цвет',
        twoColors: '2 цвета',
        color: 'Цвет ляссе',
        color2: 'Цвет 2-го ляссе',
      },
      flyleafs: {
        flyleafs: 'Форзацы',
        colored: 'Цветной форзац/нахзац',
        pocket: 'Самоклеящийся карман для визитки',
        individual: 'Индивидуальный форзац/нахзац',
        position: 'Форзац/нахзац',
        both: 'Форзац и нахзац',
        front: 'Форзац',
        end: 'Нахзац',
        uploadFront: 'Загрузить изображение на форзац',
        uploadEnd: 'Загрузить изображение на нахзац',
        color: 'Цвет',
      },
      inserts: {
        inserts: 'Вклейки',
        zero: 'Печать и вклейка рекламных листов 4/0',
        four: 'Печать и вклейка рекламных листов 4/4',
        ready: 'Вклейка готовых рекламных листов',
        quantity: ' Количество листов',
        one: '1 лист',
        two: '2 листа',
        position: 'Положение листов',
        coverPlanner: 'Печать обложки планера',
      },
      slice: {
        slice: 'Обрез',
        gilding: 'Золочение/серебрение',
        coloring: 'Окраска',
        print: 'Печать',
        chameleon: 'Хамелеон',
        color: 'Цвет',
        gold: 'Золото',
        silver: 'Серебро',
        corner: 'Угол блока',
        rounded: 'Скругленный',
        square: 'Прямой',
        upload: 'Загрузить изображение',
        sliceChameleon: 'Верх/низ',
      },
      envelope: {
        envelope: 'Конверт на нахзац',
        color: 'Цвет',
        white: 'белый',
        toned: 'тонированный',
      },
      rounding: {
        rounding: 'Скругление угла блока',
      },
      pocket: {
        pocket: 'Самоклеящийся карман для визитки',
        position: 'Расположение',
        front: 'Форзац',
        end: 'Нахзац',
      },

      additionalBlock: {
        additionalBlock: 'Дополнительный блок',
      },
      spine: {
        spine: 'Спайн',
        color: 'Цвет резинки',
        material: 'Материал',
      },
      pocketMaterials: {
        pocketMaterials: 'Материалы карманов',
      },
      zipper: {
        zipper: 'Молния',
        color: 'Цвет молнии',
        buttonColor: 'Цвет кнопки',
      },
      button: {
        button: 'Кнопка',
        color: 'Цвет кнопки',
      },
      captal: {
        captal: 'Каптал',
        color: 'Цвет каптала',
      },
      pocketMaterial: {
        pocketMaterial: 'Материал карманов',
        pocketBackMaterial: 'Материал карманов оборота',
      },
      stitchingRubber: {
        stitchingRubber: 'Резинка',
        color: 'Цвет резинки',
      },
      spring: {
        spring: 'Пружина',
        color: 'Цвет',
      },
      spunbond: {
        spunbond: 'Спанбонд',
        color: 'Цвет',
      },

      heatShrink: {
        heatShrink: 'Термоусадка',
      },
      packing: {
        packing: 'Упаковка в коробку',
      },
    },
  },
  en: {
    ui: {
      outOfStock: 'Out of stock',
      inStock: 'In stock',
      inStockFor: 'In stock for',
      onTheWay: 'On the way',
      availability: 'Availability',
      pcs: 'pcs',
      cm: 'cm',
      cm2: 'cm²',
      m: 'm',
      for: 'for',
      productsOfFormat: 'products of the format',
      runM: 'run. m',
      pages: 'no pages | {n} page | {n} pages',
      weeks: '{n}th week',

      cornersCover: 'Corners of cover',
      rounded: 'Rounded',
      square: 'Square',
      roundedCorner: 'Rounded corner',
      squareCorner: 'Square corner',

      save: 'Save',
      add: 'Add',
      continue: 'Continue',
      next: 'Next',
      stitchingParameters: 'Stitching parameters',

      persList: 'Personalizations list',

      // new

      filters: 'Filters',
      blockType: 'Block type',
      format: 'Format',

      materialType: 'Material type',
      color: 'Color',
      name: 'Name',
      sale: 'Sale',

      upload: 'Upload an image to the cover',

      // chooseValue: 'Choose value',
      // changeLayout: 'switch keyboard layout',
      // notAvailable: 'Not available',
      // above: 'above {tiraz} pc',
      // pieces: 'pc.',
      // sku: 'SKU',
      // block: 'Block',
      // relatedItems: 'Off-the-shelf items',
      // orderConstructor: 'Order from constructor',
      // share: 'Share',
      // withCirculation: ' with circulation of ',
      // circulation: 'Circulation',
      // nearPanton: 'Near Pantone',
      // material: 'Material',
      // colors: 'Colors',
      // recommendPersons: 'Recommended personalizations',

      sorts: 'Ascending',
      products: 'Products',
      models: 'Model',
      springs: 'Spring',
      blocks: 'Block type',
      formats: 'Block format',
      materials: 'Name',
      types: 'Material type',
      new: 'New',

      inputErrorMin: 'minimum value {n}',
      inputErrorMax: 'maximum value {n}',

      inputErrorMinWidth: 'minimum length {n} mm',
      inputErrorMaxWidth: 'maximum length {n} mm',

      inputErrorMinHeight: 'minimum height {n} mm',
      inputErrorMaxHeight: 'maximum height {n} mm',
    },

    title: {
      product: 'Product selection',
      model: 'Model selection',
      block: 'Cover and block selection',
      materials: 'Material selection',
      personalizations: 'Personalizations selection',
      orders: 'Registration',
      dev: 'Test page',
    },

    navigator: {
      title: {
        product: 'Step 1: Product',
        model: 'Step 2: Cover and block',
        block: 'Step 2: Cover and block',
        materials: 'Step 3: Material',
        personalizations: 'Step 4: Personalization',
        orders: 'Step 5: Registration',
        add: ' ',
      },
      label: {
        product: 'Products',
        model: ' ',
        block: 'Cover and block',
        materials: 'Material',
        personalizations: 'Personalization',
        orders: 'Making an order',
        add: 'Add another product',
      },
    },
    priceBar: {
      forOne: 'For 1 pcs.',
      forEdition: 'For edition',
      from: 'from',
      rub: 'rub.',
      minEdition: 'The minimum print run is 20 pcs.',
      extraCharge:
        ' Taking into account the circulation margin of {discount}% with a circulation of {quantity} to {quantityNext} pcs.',
      noDiscount: 'The circulation discount is taken into account from  300 pcs.',
      discount:
        'Taking into account the circulation discount of {discount}% for a circulation from {quantity} to {quantityNext} pcs.',
      discountUnlim:
        'Taking into account the circulation discount of {discount}% with a circulation of {quantity} pcs.',
      extraChargeUnlim: 'Taking into account the circulation margin {discount}% for a circulation of {quantity} pcs.',
      noDiscountOne: 'Price excluding discounts.',
      noDiscountEdition: 'The cost of the edition run excluding discounts.',
      withDiscount: 'Discounted ',
      discountInfo: 'The discount consists of{n}.',
      discountInfo2: 'Taking into account{n}discount.',
      discountInfoDealer: '<span style="color:#2FFFFB;">Dealer</span>',
      discountInfoEdition: '<span style="color:#FF6868;">Circulation</span>',
      discountInfoSeason: '<span style="color:#6FFF97;">Seasonal</span>',
      discountInfoAnd: 'and',
      discountInfoDealer2: '<span style="color:#2FFFFB;">Dealer</span> - {dealer}%',
      discountInfoEdition2: '<span style="color:#FF6868;">Circulation</span> - {edition}%',
      discountInfoSeason2: '<span style="color:#6FFF97;">Seasonal</span> - {season}%',
    },
    cart: {
      addProduct: 'Add product',
      saveProduct: 'Save product',
      totalCost: 'Total cost',
      personalizations: 'Personalizations',
      edit: 'Edit product',
      downloadTemplate: 'Download template',
      downloadImages: 'Download images',
      zoom: 'Zoom in',
    },
    preview: {
      cover: 'Cover',
      spread: 'Spread',
      flyleaffront: 'Flyleafs',
      flyleafend: 'Flyleafs',
      edge: 'Edge',
      front: 'Front-flyleaf',
      end: 'End-flyleaf',
      notLayout: 'The image is not a layout',
    },

    product: {
      header: 'Constructor of diaries',
      subHeader: 'Create your own unique product',
      header2: 'Choose an item',
      video: 'Video instruction',
    },

    model: {
      choose: 'Choose a model of ',
      p1: 'diary',
      p2: 'weekly planner',
      p3: 'planner',
      p4: 'alphabet book',
      p7: 'city notebook',
    },

    block: {
      cover: 'Cover',
      block: 'Block',
      art: 'Art',
      main: 'Main block',
      additional: 'Additional block',
      exist: 'Exist',
      notExist: 'Not exist',
      pages: 'pgs.',
      options: {
        typeBlock: 'Block type',
        product: 'Product type',
        model: 'Model',
        paper: 'Paper',
        forzac: 'End / front leaves',
        blockSize: 'Format',
        numbersPage: 'Page number',
        abcBook: 'Alphabet book',
        blockCorner: 'Block corner',
        delivery: 'Nearest delivery',
      },
    },
    materials: {
      material: 'Material',
      materials: 'Simple cover',
      stitchings: 'Combined Materials',
      laminates: 'Laminated Covers',
      stitching: 'Stitching',

      combi1: 'Combi 1',
      combi2: 'Combi 2',
      combi3: 'Combi 3',
      combi4: 'Combi 3+',

      m1: 'Material 1',
      m2: 'Material 2',
      m3: 'Material 3',
      m4: 'Material 4',
      outer: 'Outer material',
      back: 'Back material',
      internal: 'Internal material',
      spine: 'Color of the spine',
      pocket: 'Pocket material',
      backPocket: 'Back pocket material',

      fullColorHint: 'Suitable for full-color printing',

      options: {
        pantone: 'Nearest Pantone',
        type: 'Material type',
      },
    },
    personalizations: {
      personalizations: 'Personalizations',

      tabs: {
        cover: 'Cover',
        block: 'Block',
        options: 'Product options',
        packaging: 'Packaging',
      },

      preview: 'Appearance',
      control: 'Parameters',
      clear: 'Clear list',

      embossing: {
        embossing: 'Embossing',
        pancake: 'Pancake embossing',
        foil: 'Foil stamping',
        coloredFoil: 'Stamping with colored foil',
        clicheType: 'Cliche',
        individual: 'Individual embossing',
        standart: 'Standard embossing',
        area: 'Area',
        cliche: 'Cliche selection',
        to50: 'up to 50 cm²',
        to100: 'up to 100 cm²',
        from100: 'from 100 cm²',
        width: 'Width',
        height: 'Height',
        spine: 'Stamping on the spine',
        clicheMaking: 'Making cliches',
        foilColor: 'Foil color',
        upload: 'Upload an image',
      },

      printing: {
        printing: 'Printing by material',
        solvent: 'Solvent printing',
        colorUV: 'UV printing',
        reliefUV: 'Relief UV printing',
        foil: 'Foil printing',
        area: 'Area',
        to50: 'up to 50 cm²',
        to100: 'up to 100 cm²',
        to200: 'up to 200 cm²',
        to340: 'up to 340 cm²',
        to680: 'up to 680 cm²',
        to1200: 'up to 1200 cm²',
        areaFull: 'Print to the entire area',
        upload: 'Upload an image',
      },

      foil: { foil: 'Foil printing' },
      flocking: { flocking: 'Selective flocking' },

      uvVarnish: {
        uvVarnish: 'Selective UV varnish',
      },

      sliceColoring: {
        sliceColoring: 'Slice coloring',
        color: 'Color',
      },

      flap: {
        flap: 'Flap, pocket',
        flap1: 'Flap 1',
        flap2: 'Flap 2',
        flap4: 'Flap 4',
        flap5: 'Flap 5',
        flap6: 'Flap 6',
        flap7: 'Edged corner',
        flap8: 'Flap №8',
        flap9: 'Flap №9',
        flap10: 'Flap №10',
        flap11: 'Flap №11',
        magnet: 'Magnetic flap',
        organizer: 'A patch pocket "Organizer"',
        trinity: 'Pocket "Troika"',
        trinityH: 'Pocket "Troika"',
        material: 'Material',
        materialBottom: 'Material for the bottom pocket',
        materialTop: 'Material for the upper pocket',
        materialPocket: 'Material for loop and pocket',
        rubber20Color: 'Rubber band 20 mm',
        threadColor: 'Thread color',
        buttonColor: 'Button color',
        sliceColor: 'Slice color',
        position: 'Pocket position',
        description: {
          flap1: 'on a button with a handle loop',
          flap2: 'with a handle loop',
          flap4: 'on a magnetic button with a handle loop',
          // flap5: '',
          flap6: 'on a hidden magnetic button',
          flap7: 'on a mechanical button with a cropped corner of the diary',
          flap8: 'on a hidden magnet with a cut color',
          flap9: 'on a mechanical button with slots for the handle',
          flap10: 'on a magnetic button with a cut color',
          flap11: 'on a mechanical button',
          magnet: 'with pocket and handle loop',
          // organizer: '',
          trinity: 'perimeter firmware',
          trinityH: 'perimeter firmware',
        },
      },

      rubber: {
        rubber: 'Rubber',
        '2mm': 'Rubber  2mm',
        '2mmHorizontal': 'Rubber 2 mm, horizontal',
        '6mm': 'Rubber 6mm',
        '10mm': 'Rubber 10mm',
        '15mm': 'Rubber 15mm',
        color: 'Rubber color',
        grip: 'Grip',
        material: 'Material',
        materialNameplate: 'Nameplate material',
        materialGrip: 'Grip material',
        nameplate: 'Nameplate',
        circle20x20: 'Metal, circle 20x20 mm',
        rect15x30: 'Metal, rectangle 15x30 mm',
        rect115x16: '16x115 mm',
        without: 'Without a nameplate',
        drawing: 'Drawing',
        uvPrinting: 'UV printing',
        engraving: 'Engraving',
        nameplateColor: 'Nameplate color',
        gold: 'Gold',
        silver: 'Silver',
        nameplateMaking: 'Making a nameplate',
      },
      loop: {
        loop: 'Loop under the handle',
        '15mm': 'Handle loop, 15mm',
        '20mm': 'Elastic loop for handle, 20mm',
        pen: 'Handle loop',
        penDouble: 'Double handle loop',
        penLabel: 'Loop-label for a pen on the cover',
        color: 'Rubber color',
        material: 'Material',
        material1: 'Material of the first loop',
        material2: 'Material of the second loop',
        threadColor: 'Thread color',
      },
      seamPerimeter: {
        seamPerimeter: 'Seam perimeter',
        color: 'Thread color',
      },
      plastic: {
        plastic: 'Plastic side',
        vertical: 'Vertical on one side',
        sphere: 'Sphere',
        verticalBoth: 'Vertical on both sides',
      },
      perforation: {
        perforation: 'Perforation of the spine',
        color: 'Color',
      },
      cutting: {
        cutting: 'Cutting down',
        individual: 'Individual cutting',
      },
      cuttingPrint: {
        cuttingPrint: 'Individual cutting with UV printing on plastic',
      },
      polymer: {
        polymer: 'Polymer sticker',
        area: 'Square',
        '4cm': 'up to 4 cm²',
        '9cm': 'up to 9 cm²',
        '25cm': 'up to 25 cm²',
      },
      metallic: {
        metallic: 'Metal sticker',
        area: 'Square',
        '4cm': 'up to 4 cm²',
        '9cm': 'up to 9 cm²',
        '25cm': 'up to 25 cm²',
        color: 'Color',
      },
      stereo: { stereo: 'Stereo vario stickers' },
      laser: { laser: 'Foil Sticker with Laser Engraving' },
      metallicAccessories: {
        metallicAccessories: 'Metal Accessories',
        corners: 'Corners',
        frames: 'Frames',
        label: 'Label',
        '2pcs': '2 pcs.',
        '4pcs': '4 pcs.',
        color: 'Color',
        front: 'Front',
        back: 'Back',
        both: 'Front и back',
        engravingMaking: 'Engraving on one side',
      },
      nfc: {
        nfc: 'NFC Tag',
        cliche: 'Cliche selection',
        recording: 'Recording NFC tags',
      },
      magnet: {
        magnet: 'Hidden magnets under the pen',
        cliche: 'Cliche selection',
      },

      lasse: {
        lasse: 'Lasse',
        nameplate: 'Logoprint / nameplate',
        without: 'Without logoprint and nameplate',
        twosided: 'Two-sided logoprint',
        metal: 'Metal nameplate',
        plexiglass: 'Plexiglass nameplate',
        single: 'Individual lasse',
        double: 'Double individual lasse',
        drawing: 'Drawing',
        uvPrinting: 'UV printing',
        engraving: 'Engraving',
        sidesQty: 'Number of sides',
        withoutSides: 'Without engraving',
        oneSide: 'On one side',
        bothSides: 'On both sides',
        form: 'Form',
        square: 'Square',
        circle: 'Circle',
        rect: 'Rectangle',
        nameplateColor: 'Nameplate color',
        gold: 'Gold',
        silver: 'Silver',

        printing: 'Printing on a lasse',
        printing2: 'Printing on the 2nd lasse',
        withoutPrinting: 'Without printing',
        oneColor: '1 color',
        twoColors: '2 colors',
        color: 'Lasse color',
        color2: 'The color of the 2nd lasse',
      },
      flyleafs: {
        flyleafs: 'Flyleafs',
        colored: 'Color flyleaf/flyleaf',
        pocket: 'Self-adhesive pocket for business cards',
        individual: 'Individual flyleaf/flyleaf',
        position: 'Front-flyleaf/End-flyleaf',
        both: 'Front-flyleaf and End-flyleaf',
        front: 'Front-flyleaf',
        end: 'End-flyleaf',
        uploadFront: 'Upload an image to the front-flyleaf',
        uploadEnd: 'Upload an image to the end-flyleaf',
        color: 'Color',
      },
      inserts: {
        inserts: 'Inserts',
        zero: 'Printing and pasting of advertising sheets 4/0',
        four: 'Printing and pasting of advertising sheets 4/4',
        ready: 'Pasting of ready-made advertising sheets',
        quantity: 'Number of sheets',
        one: '1 sheet',
        two: '2 sheets',
        position: 'Position of sheets',
        coverPlanner: 'Printing the planner covers',
      },
      slice: {
        slice: 'Slice',
        gilding: 'Gilding/silvering',
        coloring: 'Coloring',
        print: 'Print',
        chameleon: 'Chameleon',
        color: 'Color',
        gold: 'Gold',
        silver: 'Silver',
        corner: 'Corner of the block',
        rounded: 'Rounded',
        square: 'Square',
        upload: 'Upload an image',
        sliceChameleon: 'Top/bottom',
      },
      envelope: {
        envelope: 'The envelope on end leaves',
        color: 'Color',
        white: 'white',
        toned: 'toned',
      },
      rounding: {
        rounding: 'Rounding the corner of the block',
      },
      pocket: {
        pocket: 'Self-adhesive pocket for business cards',
        position: 'Position',
        front: 'Front-flyleaf',
        end: 'End-flyleaf',
      },

      additionalBlock: {
        additionalBlock: 'Additional block',
      },
      spine: {
        spine: 'Spine',
        color: 'Spine color',
        material: 'Material',
      },
      pocketMaterials: {
        pocketMaterials: 'Pocket materials',
      },
      zipper: {
        zipper: 'Zipper',
        zipperColor: 'Zipper color',
        color: 'Button color',
      },
      button: {
        button: 'Button',
        color: 'Button color',
      },
      captal: {
        captal: 'Captal',
        color: 'Captal color',
      },
      pocketMaterial: {
        pocketMaterial: 'Pocket material',
        pocketBackMaterial: 'Pocket back material',
      },
      stitchingRubber: {
        stitchingRubber: 'Rubber',
        color: 'Rubber color',
      },
      spring: {
        spring: 'Spring',
        color: 'Color',
      },
      spunbond: {
        spunbond: 'Spunbond',
        color: 'Color',
      },

      heatShrink: {
        heatShrink: 'Heat shrink',
      },
      packing: {
        packing: 'Packing in a box',
      },
    },
  },
}

function customRule(choice, choicesLength) {
  if (choice === 0) {
    return 0
  }
  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }
  return choicesLength < 4 ? 2 : 3
}

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  pluralizationRules: {
    ru: customRule,
  },
  locales: ['ru', 'en'],
  messages,
})
