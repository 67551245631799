import lodash from 'lodash'
import { getItemsBuhtaByConfig } from '/src/utils/getItemsBuhtaByConfig'

export const rounding = {
  id: 'rounding',
  tName: 'personalizations.rounding.rounding',
  template: 'boolean',

  originPersonalizationsIds: [
    561, // Скругление уголков блока 10х16, 15х21, 20х26
    746, // Скругление уголков блока для Классики(для M13/M27)
  ],

  getPersConfig,
  getItemsBuhta,
}

/** Формирует массив с выбранными персоналками и моделями
 * (нужно для отображения на 4-м шаге) */
function getPersConfig(store, oldModelValues) {
  const items = []
  const modelValues = {}

  const params = {
    store,
    oldModelValues,
    items,
    modelValues,
    originIdsAvailable: this.originIdsAvailable,
    originPersonalizations: this.originPersonalizations,
  }

  getRounding(params)

  return {
    modelValues,
    items: lodash.sortBy(items, ['sort']),
  }
}

/** Формирует массив с выбранными персоналками бухты
 * (нужно для запроса цены) */
function getItemsBuhta({ modelValues }) {
  if (modelValues?.rounding?.checked) {
    const originPersonalizations = this.originPersonalizations
    let idBuhta = 561
    if (originPersonalizations[746]) {
      idBuhta = 746
    }

    return getItemsBuhtaByConfig(originPersonalizations, idBuhta, [])
  } else return []
}

/**
 * Скругление угла блока
 */

function getRounding(params) {
  const { oldModelValues, items, modelValues, originPersonalizations } = params

  let values = []

  if (originPersonalizations[561] || originPersonalizations[746]) {
    values = [
      { id: 0, checked: true },
      { id: 1, checked: false },
    ]

    if (values.length > 0) {
      items.push({
        id: 'rounding',
        tName: 'personalizations.rounding.rounding',
        template: 'checkbox',
        sort: 10,
        values,
      })
      if (oldModelValues.rounding) {
        modelValues.rounding = values.find((i) => i.checked === oldModelValues.rounding.checked) ?? values[0]
      } else {
        modelValues.rounding = values[0]
      }
    }
  }
}
